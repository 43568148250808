/* You can add global styles to this file, and also import other style files */
@import './src/assets/scss/fonts';
@import './src/assets/scss/colors';
@import './src/assets/scss/inputs';
@import './src/assets/scss/sidebar-and-main-panel';

body {
  position: relative;
  background: $color-background;
  width: 100vw;
  height: 100vh;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-container {
  z-index: 10000;
}
