/* FONSTS */
@font-face {
  font-family: 'WorkSans-Medium';
  src: url('../fonts/WorkSans-Medium.ttf') format('truetype'),
  // url('../fonts/SamsungSharpSans-Bold.woff') format('woff'),
}

@font-face {
  font-family: 'WorkSans-Regular';
  src: url('../fonts/WorkSans-Regular.ttf') format('truetype'),
  // url('../fonts/SamsungSharpSans-Bold.woff') format('woff'),
}

@font-face {
  font-family: 'WorkSans-Thin';
  src: url('../fonts/WorkSans-Thin.ttf') format('truetype'),
  // url('../fonts/SamsungSharpSans-Bold.woff') format('woff'),
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url('../fonts/WorkSans-SemiBold.ttf') format('truetype'),
  // url('../fonts/SamsungSharpSans-Bold.woff') format('woff'),
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: url('../fonts/WorkSans-Bold.ttf') format('truetype'),
  // url('../fonts/SamsungSharpSans-Bold.woff') format('woff'),
}

@font-face {
  font-family: 'WorkSans-Light';
  src: url('../fonts/WorkSans-Light.ttf') format('truetype'),
  // url('../fonts/SamsungSharpSans-Bold.woff') format('woff'),
}
